/* eslint-disable import/extensions */
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import jwtDecode from 'jwt-decode'; // decode auth token
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import { checkFileSizeInMb } from './javascript/fileSizeCheck.js';
import { checkImageSizeAfterCrop } from './javascript/imageSizeAfterCrop.js';
import { displayToast } from './javascript/Notification'; // Error component
import { getLastCharacter } from './javascript/lastCharacter';
import { Toast } from './javascript/toast';
import '@claviska/jquery-minicolors';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'jquery-ui/ui/widgets/sortable.js';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// yarl CSS
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';

// plyr CSS
import 'plyr/dist/plyr.css';

// react noUI CSS
import 'nouislider/dist/nouislider.css';

// jQuery MiniColors CSS
import '@claviska/jquery-minicolors/jquery.minicolors.css';

// start the Stimulus application
import './bootstrap';

import './javascript/modals.js';

import './javascript/jquery-extension/jquery-toggle-disable-button.js';

// custom currency functions
import './javascript/currency.js';

// eslint-disable-next-line import/no-extraneous-dependencies
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min.js');

// Adds global jQuery for usage
const $ = require('jquery');
global.$ = global.jQuery = $;

// Adds toast for global usage
global.Toast = Toast;

// Adds checkFileSizeInMb for global usage
global.checkFileSizeInMb = checkFileSizeInMb;

// Adds checkImageSizeAfterCrop for global usage
global.checkImageSizeAfterCrop = checkImageSizeAfterCrop;

// Adds displayToast for global usage
global.displayToast = displayToast;

// Adds getLastCharacter for global usage
global.getLastCharacter = getLastCharacter;

// Adds jwtDecode for global usage
global.jwtDecode = jwtDecode;

// Adds moment for global usage
global.moment = moment;
