import { displayToast } from './Notification';
let croppedWebPBlob = null;

export const checkImageSizeAfterCrop = (
  cropperInstance,
  maxFileSize,
  errorMessage,
  callback
) => {
  if (cropperInstance) {
    cropperInstance.getCroppedCanvas().toBlob(
      (blob) => {
        if (blob) {
          const fileSizeInMB = parseFloat(
            (blob.size / (1024 * 1024)).toFixed(2)
          );

          // Restrict file size after cropping & converting to WebP
          if (fileSizeInMB > parseFloat(maxFileSize)) {
            return displayToast(errorMessage, 'error');
          }

          // Store the WebP blob
          croppedWebPBlob = blob;

          callback(croppedWebPBlob);
        }
      },
      'image/webp',
      0.8
    );
  }
};
