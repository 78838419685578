const $ = require('jquery');

function formatPrice (amount, currencyCode) {
    return new Intl.NumberFormat('en-GB', {style: 'currency', currency: currencyCode}).format(amount); // en-GB does not affect on script works
}

function extractAmount (price) {
    return parseFloat(price.replace(/[^0-9.-]+/g,""));
}

function extractCurrencyCode (price){
    var currencyCodes = {
        '$' :'USD', // US Dollar
        '€' :'EUR', // Euro
        '₡' :'CRC', // Costa Rican Colón
        '£' :'GBP', // British Pound Sterling
        '₪' :'ILS', // Israeli New Sheqel
        '₹' :'INR', // Indian Rupee
        '¥' :'JPY', // Japanese Yen
        '₩' :'KRW', // South Korean Won
        '₦' :'NGN', // Nigerian Naira
        '₱' :'PHP', // Philippine Peso
        'zł':'PLN', // Polish Zloty
        '₲' :'PYG', // Paraguayan Guarani
        '฿' :'THB', // Thai Baht
        '₴' :'UAH', // Ukrainian Hryvnia
        '₫' :'VND'  // Vietnamese Dong
    };

    let currencySymbol = price.replace(/[\d,.\s]/g, "");

    if(currencyCodes[currencySymbol]){
        return currencyCodes[currencySymbol];
    } else {
        return currencySymbol;
    }
}

global.formatPrice = formatPrice;
global.extractAmount = extractAmount;
global.extractCurrencyCode = extractCurrencyCode;