const $ = require('jquery');

/**
 * Class to show Toasts on BrickTribe website, requires material-icons and tailwindcss
 */
export class Toast {
    /**
     *
     * @param {string} message Message to be displayed to the user
     * @param {string} type [success, info, warning, error, success-full, info-full, warning-full, error-full]
     * @param {number} timeout Time in ms when the toast gets removed (0 = infinite)
     * @param {string} position Position of the toast (centered `top` or `bottom` on mobile devices) [bottom-right, bottom-left, top-right, top-left]
     * @param {boolean} showIcon If we want to show the icon on the left of the toast
     */
    constructor(message, type = 'success', timeout = 3000, position = 'bottom-right', showIcon = true) {
        this.container = $('body #toast-container-' + position);
        //If the container does not exist we create it
        if (this.container.length !== 1) {
            this.container = $(this.createContainer(position)).appendTo('body');
        }

        this.addToast(this.createToast(message, type, position, showIcon), timeout);
    }

    /**
     * Used internal to add toast to current container
     * @private
     * @param html HTML to be appended
     * @param timeout Timeout for the HTML to disappear
     */
    addToast(html, timeout) {
        let $element = $(html).appendTo(this.container);

        if(timeout > 0) {
            setTimeout(function () {
                $element.remove();
            }, timeout);
        }
    }

    /**
     * Used internal to create the Toast HTML
     * @private
     * @param {string} message
     * @param {string} type
     * @param {string} position
     * @param {boolean} showIcon
     * @returns {string} HTML
     */
    createToast(message, type, position, showIcon) {
        let iconVisible = showIcon? '' : 'hidden';
        let classes;
        let icon;
        switch (type) {
            case 'success-full':
                classes = 'bg-green-600 text-green-50';
                icon = 'check_circle'
                break;
            case 'info-full':
                classes = 'bg-blue-600 text-blue-50';
                icon = 'info';
                break;
            case 'warning-full':
                classes = 'bg-yellow-600 text-yellow-50';
                icon = 'warning';
                break;
            case 'error-full':
                classes = 'bg-red-600 text-red-50';
                icon = 'error';
                break;
            case 'success':
                classes = 'bg-green-100 text-green-800';
                icon = 'check_circle'
                break;
            case 'info':
                classes = 'bg-blue-100 text-blue-800';
                icon = 'info';
                break;
            case 'warning':
                classes = 'bg-yellow-100 text-yellow-800';
                icon = 'warning';
                break;
            case 'error':
                classes = 'bg-red-100 text-red-800';
                icon = 'error';
                break;
            default:
                console.error(`No such type like '${type}'`);
                break;
        }

        let animation;
        switch (position) {
            case 'top-left':
                animation  = 'slide-from-left';
                break;
            case 'top-right':
                animation = 'slide-from-right';
                break;
            case 'bottom-left':
                animation = 'slide-from-left';
                break;
            case 'bottom-right':
                animation = 'slide-from-right';
                break;
            default:
                console.error(`Position: '${position}' is not valid.`);
                break;
        }

        return `<div class="w-72 md:w-80 flex mt-2 mb-2 ${classes} items-center" style="animation: ${animation} 200ms ease-in;">
                              <span class="material-icons p-2 md:p-4 ${iconVisible} align-middle">
                                ${icon}
                              </span>
                              <div class="p-2 md:p-4 break-words">${message}</div>
                            </div>`;
    }

    /**
     * Used internal to create the container for the Toasts
     * @private
     * @param position
     * @returns {string}
     */
    createContainer(position) {
        let positionClass;
        switch (position) {
            case 'top-left':
                positionClass = 'top-0 sm:top-4 sm:left-4'
                break;
            case 'top-right':
                positionClass = 'top-0 sm:top-4 sm:right-4'
                break;
            case 'bottom-left':
                positionClass = 'bottom-0 sm:bottom-4 sm:left-4'
                break;
            case 'bottom-right':
                positionClass = 'bottom-0 sm:bottom-4 sm:right-4'
                break;
            default:
                console.error(`Position: '${position}' is not valid.`);
                break;
        }

        return `<div id="toast-container-${position}" class="toast fixed ${positionClass} inset-x-0 sm:inset-x-auto z-50 overflow-x-hidden flex justify-center items-center flex-col md:block"></div>`;
    }
}