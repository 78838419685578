export const displayToast = (message, type, floating = false) => {
  const errorElem = document.createElement('div');
  errorElem.className = `${
    floating
      ? 'fixed whitespace-nowrap bottom-8 p-3 flex items-center gap-3 left-1/2 transform -translate-x-1/2 text-base text-blue-800'
      : 'w-full fixed bottom-0 inset-x-0 text-center text-base py-5 text-white'
  } ${
    type == 'error'
      ? 'bg-red-500'
      : type == 'success'
      ? 'bg-green-500'
      : type == 'warning'
      ? 'bg-yellow-500'
      : 'bg-blue-100'
  }`;
  errorElem.style.zIndex = '1111';
  errorElem.innerHTML = message;
  document.getElementsByTagName('body')[0].appendChild(errorElem);

  setTimeout(() => {
    errorElem.remove();
  }, 5000);
  return;
};
